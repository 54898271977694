//these variables will be changed in a future task
$light-grey-2: #f1f3f7;
$light-grey-3: #eef0f5;
$light-grey-4: #d1d7df;
$grey-1: #abb0b9;

// variables based on Figma file: https://www.figma.com/design/B2JpAzxUdR6thrjYJFXy3V/Global-components?node-id=3203-17684&node-type=canvas&m=dev
$blue-10: #001e30;
$blue-20: #003450;
$blue-30: #004b71;
$blue-42: #136999;
$blue-56: #118fc5;
$blue-64: #00a6de;
$blue-71: #1ebbf0;
$blue-74: #47cdf5;
$blue-78: #67daff;
$blue-85: #8cdfff;
$blue-89: #b0e7ff;
$blue-92: #c5f0ff;
$blue-95: #d9f7ff;
$blue-97: #e9faff;
$blue-99: #f8fdff;
$azure-10: #001b3d;
$azure-20: #003063;
$azure-30: #11468a;
$azure-40: #005db8;
$azure-46: #1369d9;
$azure-56: #3286f4;
$azure-66: #68a2ef;
$azure-80: #a5c8ff;
$azure-86: #bddaff;
$azure-98: #f5f9fe;
$azure-99: #fbfcff;
$violet-10: #150066;
$violet-20: #2700a0;
$violet-33: #3c2fd4;
$violet-40: #4643e9;
$violet-49: #5d5cff;
$violet-60: #8283ff;
$violet-67: #9898ff;
$violet-79: #bebeff;
$violet-89: #dfdeff;
$violet-98: #f8f8ff;
$violet-99: #fdfbff;
$indigo-5: #0a0727;
$indigo-8: #170f3f;
$indigo-13: #231655;
$indigo-19: #31216b;
$indigo-26: #3b317e;
$indigo-33: #45449b;
$indigo-39: #4752b8;
$indigo-51: #6872cf;
$indigo-58: #7785d8;
$indigo-74: #aab2e7;
$indigo-84: #ccd1f4;
$indigo-97: #f5f7ff;
$indigo-99: #fdfcff;
$teal-10: #001f23;
$teal-20: #00363b;
$teal-30: #004f56;
$teal-40: #006972;
$teal-50: #008591;
$teal-60: #00a0af;
$teal-68: #43b5c0;
$teal-81: #8bd4db;
$teal-91: #c5edec;
$teal-98: #f2fdfc;
$teal-99: #fafcfc;
$green-10: #002203;
$green-20: #003908;
$green-30: #005310;
$green-40: #006e18;
$green-50: #008a21;
$green-62: #2bab35;
$green-71: #45c54f;
$green-78: #57d968;
$green-84: #80e98e;
$green-92: #bef5c4;
$green-97: #f3f8f0;
$green-99: #fafcf7;
$yellow-10: #2b1700;
$yellow-20: #472a00;
$yellow-30: #663e00;
$yellow-40: #865300;
$yellow-50: #a86900;
$yellow-60: #cb8000;
$yellow-73: #fb9f00;
$yellow-76: #ffab00;
$yellow-79: #ffb648;
$yellow-87: #ffd38f;
$yellow-93: #ffe9bf;
$yellow-98: #fffaf0;
$orange-10: #380d00;
$orange-20: #5a1b00;
$orange-30: #802a00;
$orange-40: #a73a00;
$orange-50: #d14a00;
$orange-55: #e4550c;
$orange-59: #f45c0f;
$orange-70: #ff8d52;
$orange-82: #fabe9f;
$orange-91: #fddecf;
$orange-97: #fff5f0;
$red-10: #410004;
$red-20: #68000b;
$red-30: #930014;
$red-38: #b50810;
$red-50: #e32d34;
$red-60: #f35e65;
$red-70: #fe8893;
$red-76: #ffa3aa;
$red-88: #ffd0d4;
$red-97: #fff5f6;
$yellow-orange-red-99: #fffbff;
$gray-10: #151c28;
$gray-34: #484e6b;
$gray-24: #323856;
$gray-41: #59607d;
$gray-53: #767d99;
$gray-66: #989fbb;
$gray-75: #b1b9d3;
$gray-83: #cacfe6;
$gray-91: #e2e5f3;
$gray-97: #f4f6fa;
$gray-98: #f7f9fc;
$gray-99: #fbfcff;
$white-100: #ffffff;

$translucent-black: rgba(0, 0, 0, 0.6);
$translucent-deep-purple: rgba(23, 15, 63, 0.14);
$box-shadow-m: 0px 5px 15px 0px #170f3f33;

$font-weight-regular: 400;

$content-page-max-width: 800px;

$skeleton-gradient: linear-gradient(-90deg, $gray-98 25%, $light-grey-2 50%, $gray-98 75%);
$skeleton-gradient-learning-catalog: linear-gradient(-90deg, $gray-91 25%, $white-100 50%, $gray-91 75%);
