@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
    url(/assets/fonts/nunito/font1/Nunito-ExtraLight.ttf) format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
    url(/assets/fonts/nunito/font2/Nunito-Light.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
    url(/assets/fonts/nunito/font3/Nunito-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
    url(/assets/fonts/nunito/font4/Nunito-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
    url(/assets/fonts/nunito/font5/Nunito-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'), url(/assets/fonts/nunito/font6/Nunito-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
    url(/assets/fonts/nunito/font7/Nunito-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
    url(/assets/fonts/nunito/font8/Nunito-Black.ttf) format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'IbmPlexSansLight';
    src: local('IbmPlexSans'),
    url(/assets/fonts/ibm-plex-sans/IBMPlexSans-Light.ttf) format('truetype');
    font-weight: 800;
}


$fonts-breakpoint: 1000px;

.h1-plus {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 38px !important;
    line-height: 45px !important;
}

.h1-standard {
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 38px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #141921;
    margin: 0px !important;

    @media (max-width: $fonts-breakpoint) {
        //.h2-cap-standard
        font-size: 20px !important;
        font-weight: 700 !important;
        line-height: 24px !important;
        text-align: left !important;
    }
}

.h1-alternative {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 33px !important;
    
    @media (max-width: $fonts-breakpoint) {
        //.h3-standard
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 22px !important;
    }
}

.h1-extra-bold {
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 22px !important;
    line-height: 28px !important;
    text-transform: capitalize !important;
}

.h2-standard {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
}

.h2-cap-standard {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    text-align: left !important;
}

.h3-standard {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 22px !important;

    @media (max-width: $fonts-breakpoint) {
        //.n14-bold
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: 19px !important;
    }
}

.h5-regular {
    font-style: normal !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
}

.n16-link {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-decoration-line: underline !important;
    cursor: pointer;
}

.n16-cap-bold {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.02em !important;
    text-transform: capitalize !important;
}

.n16-regular-bold {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.02em !important;
}

.n16-regular {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;

    @media (max-width: $fonts-breakpoint) {
        //.n14-regular
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 19px !important;
    }
}

.n16-cap-regular {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
}

.n14-regular {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 19px !important;
}

.n14-medium {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 19px !important;
}

.n14-bold {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 19px !important;
}

.n14-cap-medium {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
}

.n12-cap-bold {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-transform: capitalize !important;
}

.n12-allcaps-bold {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-transform: uppercase !important;
}

.n12-allcaps {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    text-transform: uppercase !important;
}

.n12-regular {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}