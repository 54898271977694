@mixin hide-scroll {
  scrollbar-width: none; // Firefox compatibility

  // Works in most browsers
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 0;
  }
}

@mixin overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Always use a max-height value corresponding to the desired number of lines
// in case the line-clamp is not supported
@mixin line-clamp($lines) {
  overflow: hidden;
  
  @supports (-webkit-line-clamp: $lines) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    display: -webkit-box;
    text-overflow: ellipsis;
  }
}